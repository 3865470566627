import activityPart from './components/activityPart'
import tobeConfirmed from './components/tobeConfirmed'
import rebateBillPart from './components/rebateBillPart'
import moment from "moment";
//查询进行中列表接口 getOngoingPolicy
import { getExport } from "./api";
import Util from "@/utils/utils";
import { ctsApi } from "@/views/channel/api";
import { getMatkl } from "@/views/channelWholesalePrice/businessChannelPriceadd/api";
export default {
  name: "",
  components:{
    activityPart,
    tobeConfirmed,
    rebateBillPart
  },
  data() {
    return {
      pageLoadFlag:false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "",
          name: "",
          title: "政策返利"
        },
      ],
      businessTypeList:[
        {
          code:'C_M',
          name:'提货台返',
        },
        {
          code:'M_P',
          name:'提货返点',
        },
        {
          code:'TXT',
          name:'固定金额',
        },
      ],
      policyTypeList:['样机类','直扣','库存补差','总部电商政策','分销提货类','回款政策','提货政策','工程政策','专卖店政策','前端打折类','工程类','零售政策','订单政策','共享仓政策','代理运营商考核'],
      categoryList:[],//品类下拉列表
      filter:{
        businessType:undefined,
        policyType:undefined,
        monthDate:'',
        productCode:undefined,
        statusId:0,
      },
      isFixed:false,
      filterList:[
        {
          id:0,
          name:'活动中'
        },
        {
          id:1,
          name:'待确认'
        },
        {
          id:2,
          name:'返利账单'
        }
      ], //顶部状态筛选
      dataInfo:{}, //详细信息
      total:0, //总共搜索数量
      momentTime:'',
      policyWindowList:[]
    };
  },
  mounted() {
    this.filter.monthDate = this.$util.formatDate(new Date().getTime() - 86400000, 'Y-M')
    this.getMarktl()
    if (this.filter.statusId == 0){
      this.getList()
    }
  },


  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day');
    },
    // 获取当前商家物料组
    getMarktl() {
      let data = {
        orgId :''
      }
      getMatkl(data).then(res=>{
        this.categoryList = res.data.list
      })
    },
    categoryChang(){
      this.filter.productCode = this.filter.productCode?this.filter.productCode:''
    },
    businessTypeChang(){
      this.filter.businessType = this.filter.businessType?this.filter.businessType:''
    },
    monthDateChang(date, dateString) {
      this.filter.monthDate = dateString
    },
    // 搜索
    search() {
      this.getList()
    },
    // 重置
    reset() {
      this.filter = {
        businessType: undefined,
        monthDate: this.$util.formatDate(new Date().getTime() - 86400000, 'Y-M'),
        productCode: undefined,
        statusId:this.filter.statusId ? this.filter.statusId : 0
      }
      this.getList()
    },
    // 获取cts接口
    ctsApiData(data){
      return ctsApi(data)
    },
    // 点击导出按钮
    handleExportClick(){
      let data = {};
      let url = "";
      if(this.filter.statusId == 1){
        url = "/ppms/exportUnCheckBill.nd";
        data = {
          "acknowledgmentType": "",
          "activityName": "",
          "companyCode": "",
          "companyCodeName": "",
          "confirmDocNo": "",
          "customerCodeSp": this.$store.state.user.userInfo.customer.customerCode,
          "customerCodeSpName": "",
          "endPeriod": moment(this.filter.monthDate).format("YYYYMM"),
          "pageNum": "",
          "pageSize": "",
          "productCategory": this.filter.productCode,
          "productCategoryName": "",
          "startPeriod": moment(this.filter.monthDate).format("YYYYMM")

        }
      }else{
        url = "/ppms/exportRebateBill.nd";
        data = {
          "activityName": "",
          "cashedType": "",
          "acknowledgmentType": "",
          "companyCode": "",
          "companyCodeName": "",
          "confirmDocNo": "",
          "customerCodeSp": this.$store.state.user.userInfo.customer.customerCode,
          "customerCodeSpName": "",
          "endPeriod": moment(this.filter.monthDate).format("YYYYMM"),
          "endPeriodCreateTime": "",
          "pageNum": "",
          "pageSize": "",
          "productCategory": this.filter.productCode,
          "productCategoryName": "",
          "startPeriod": moment(this.filter.monthDate).format("YYYYMM"),
          "startPeriodCreateTime": ""
        }
      }
      getExport(data, url)
        .then(res => {
          this.pageLoadFlag = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "政策返利列表.xls");
            })
            .finally(() => {
              this.pageLoadFlag = false;
            });
        })
        .finally(res => {
          this.pageLoadFlag = false;
        });
    },
    //  活动中接口
    async getList() {
      this.pageLoadFlag = true
      let monthDate = ''
      if (this.filter.monthDate){
        monthDate = this.filter.monthDate.replace("-", "")
      }
      let data = {}
      if(this.filter.statusId == 2) {
        data = {
          "accountPeriod": monthDate,
          "companyCode": "",
          "merchantMdmCode":  this.$store.state.user.userInfo.customer.customerCode,
          "policyType": this.filter.policyType,
          "productCode": this.filter.productCode,
          "settlementType": "",
          "serviceCode":'fetchDetailsForCompletedConfirmation'
        }
      }else {
        data = {
          ...this.filter,
          "companyCode": "",
          "merchantMdmCode": this.$store.state.user.userInfo.customer.customerCode,
          settlementType:'',
          settlementStatus:this.filter.statusId==1?'4':'',
          monthDate:this.filter.statusId==0 ? this.filter.monthDate:monthDate,
          clearingSheetNoList:[],
          accountPeriod:this.filter.statusId==2 ? monthDate:'',
          "serviceCode":this.filter.statusId==0 ? "getOngoingPolicy" : this.filter.statusId==1?'retrievePendingConfirmationSubmissions':'fetchDetailsForCompletedConfirmation',
        }
      }
      const res = await this.ctsApiData(data)
      // 如果是返利账单 调用此接口 获取顶部数据
      if(this.filter.statusId == 2) {
        let headerData = {
          "merchantMdmCode": this.$store.state.user.userInfo.customer.customerCode,
          ...this.filter,
          settlementStatus:this.filter.statusId==1?'4':'',
          monthDate:this.filter.statusId==0 ? this.filter.monthDate:monthDate,
          "serviceCode":'generatePolicyStatementSummary'
        }
        const resHeaderData = await this.ctsApiData(headerData)
        this.dataInfo = resHeaderData.data.code == 200?resHeaderData.data.data:{}
      }
      if (res.data.message || res.data.code !== 200) {
        this.$message.warning(res.data.message || res.data.msg)
        this.dataInfo = {}
        this.policyWindowList = []
        this.total = 0
        this.pageLoadFlag = false
      } else  {
        this.pageLoadFlag = false
        if(this.filter.statusId !==2) {
          this.dataInfo = res.data.code == 200?res.data.data:{}
        }
        // 进行中数据处理
        if(this.filter.statusId == 0){
          this.policyWindowList = res.data.code == 200?res.data.data.ongoingPolicyVOList:[]
          this.total =  this.dataInfo && this.dataInfo.ongoingPolicyVOList? this.dataInfo.ongoingPolicyVOList.length:0
        }
        // 待确认数据处理
        if(this.filter.statusId == 1) {
          this.policyWindowList = res.data.code == 200?res.data.data.pendingOrAppealDataVOList:[]
          this.total =  this.dataInfo && this.dataInfo.pendingOrAppealDataVOList? this.dataInfo.pendingOrAppealDataVOList.length:0
        }
        // 返利
        if(this.filter.statusId == 2) {
          this.policyWindowList = res.data.code == 200?res.data.data:[]
          this.total =  this.policyWindowList.length
          this.momentTime = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
      }


   },
    clickStatusItem(id){
      this.filter.statusId = id
      if(id !== 0) {
        this.filter.businessType = ''
      }
      this.getList()
    }
  }
};
