<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handleOk"
      @cancel="cancle"
      wrapClassName="cancelNamewww"
      :width="648"
      :closable="false"
      centered
      :footer="null"
      :maskClosable="false"

    >
      <a-spin :spinning="pageLoadFlag">
        <div class="modal_close" @click="cancle">
          <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
        </div>
        <div class="modal_title_box">
        <span class="modal_title">
          <i class="modal_title_line"></i>申诉原因
        </span>
        </div>
        <div class="cancelContent">
          <div class="selectBox">
            <div class="everyBox">
              <p class="lable"><span style="color: #D9161C">*</span>申诉原因:</p>
              <a-textarea style="width: 400px"
                          v-model="message"
                          placeholder="请输入"
                          :auto-size="{ minRows: 3, maxRows: 30 }"
                          ></a-textarea>
            </div>
          </div>
        </div>
        <div class="footer">
          <h-btn
            class="fristBtn btn"
            :width="120"
            :height="40"
            content="取消"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="cancle"
          >
          </h-btn>
          <h-btn
            class="btn"
            :width="120"
            :height="40"
            content="确定"
            type="primary"
            fontSize="16"
            borderRadius="4"
            @click="handleOk"
          >
          </h-btn>
        </div>
      </a-spin>
    </a-modal>

  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      message:'',
      pageLoadFlag: false,// 整个页面的loading



    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },


  },

  watch: {

  },
  mounted() {

  },
  methods:{
    handleOk(){
      this.$emit('update:visible',false)
      this.$emit('deleteHandOk',this.message)
    },
    cancle(){
      this.$emit('update:visible',false)
      this.$emit('deleteHandCancle')
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .cancelNamewww {
  .ant-modal-content {
    text-align: center;
    border-radius: 10px;
    .ant-modal-body {
      padding: 30px 0px 30px 0px;
      .cancelContent {

        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
        .everyBox{
          margin-top: 50px;
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: center;
          .lable {
            margin-top: 8px;
            margin-right: 10px;
          }
        }
      }
      .footer {
        margin-top:50px;
        .fristBtn{
          width: 120px;
          height: 40px;
          border-radius: 4px;
          border: none;
          border: 1px solid #CCCCCC;
          color: #777777;
          background: #fff;
          text-shadow: none;
          margin-right: 24px;
          box-shadow: none;
        }
      }
      .modal_close {
        position: absolute;
        width: 42px;
        height: 42px;
        top: -30px;
        right: 0;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;

        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        //padding-bottom: 50px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding:44px 0px  30px 0;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00AAA6;
        margin-left: 24px;
      }
    }
  }

}
</style>