<template>
  <div class="activityOutBox">
    <div class="activityBox">
      <div class="topBox">
        <div class="topBoxItem">
          <p>本期返利(元)</p>
          <span>{{dataInfo.confirmAmountSum?dataInfo.confirmAmountSum.toFixed(2):'0.00'}}</span>
        </div>
        <div class="topBoxItem">
          <p>本期使用(元)</p>
          <span>{{dataInfo.claimAmountSum?dataInfo.claimAmountSum.toFixed(2):'0.00'}}</span>
        </div>
        <div class="topBoxItem">
          <p>本期余额(元)</p>
          <span>{{dataInfo.availableBalanceSum?dataInfo.availableBalanceSum.toFixed(2):'0.00'}}</span>
        </div>
        <div class="topBoxItem">
          <p>上期结转(元)</p>
          <span>{{dataInfo.lastMonthBalanceSum?dataInfo.lastMonthBalanceSum.toFixed(2):'0.00'}}</span>
        </div>
<!--        <p class="rightTips">支出<span>{{dataInfo.expenseTransactionCount?dataInfo.expenseTransactionCount:0}}</span>笔 收入<span>{{dataInfo.incomeTransactionCount?dataInfo.incomeTransactionCount:0}}</span>笔</p>-->
      </div>
    </div>
    <div class="policyWindow-bottom" v-if="policyWindowList.length>0">
      <div class="policyWindow-bottom-item" v-for="(item,index) in policyWindowList" :key="index">
        <div class="textBox">
          <p class="firstText" :title="item.activityName">{{item.promotionPlan}}</p>
          <p class="other" ><span style="margin-right:10px">确认时间:</span>{{item.confirmationTime}}</p>
          <div class="lastText hj" v-if="item.confirmationType">
            <p>
              <span class="fl" style="margin-right:10px">返利:</span>
              <span class="jg before">{{ item.rewardAmount ? Number(item.rewardAmount).toFixed(2).toString().split('.')[0] : '0' }}</span>
              <span class="jg after"><i class="dian">.</i>{{ item.rewardAmount ? Number(item.rewardAmount).toFixed(2).toString().split('.')[1] : '00'}}</span>
            </p>
          </div>
          <div class="lastText hj" v-if="item.redemptionType">
            <p>
              <span class="fl" style="margin-right:10px">兑现:</span>
              <span class="jg before dx">{{ item.rewardAmount ? Number(item.rewardAmount).toFixed(2).toString().split('.')[0] : '0' }}</span>
              <span class="jg after dx"><i class="dian">.</i>{{ item.rewardAmount ? Number(item.rewardAmount).toFixed(2).toString().split('.')[1] : '00'}}</span>
            </p>
          </div>
        </div>
        <div class="view"  @click="gotoDetail(item)">查看详情</div>
        <div class="tipBox">{{item.policyType}}</div>
      </div>
    </div>
    <div class="policyWindow-bottom" v-else>
      <div class="noList" v-if="policyWindowList.length == 0">
        <img :src="nolistImg" alt="">
        <p style="color:#777;">
          本政策无法提供明细，如需核对，请联系海信业务人员。
        </p>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  name: "",
  data() {
    return {
      nolistImg: require("@/assets/IntentionList/none.png"),

    };
  },
  props: {
    dataInfo: {
      type: [Array, Object],
      default: () => []
    },
    policyWindowList: {
      type: Array,
      default: () => []
    },
    monthDate: {
      type: String,
      default: ''
    },
  },
  watch:{
    policyWindowList:{
      handler:function(newval,oldval){},
      deep:true,
    },
    dataInfo:{
      handler:function(newval,oldval){

      },
      deep:true,
    }
  },

  methods:{
    gotoDetail(item){
      let routeUrl = this.$router.resolve({
        path:'/policyRebate/rebateDetail',
        query:{
          type:2,
          monthDate:this.monthDate,
          item:JSON.stringify(item)
        }
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push({
      //   path:'/policyRebate/rebateDetail',
      //   query:{
      //     type:2,
      //     monthDate:this.monthDate,
      //     item:JSON.stringify(item)
      //   }
      // })
    },
  }
};

</script>
<style lang="less" scoped>
.activityOutBox {
  .activityBox {
    width: 1188px;
    background: #fff;
    margin: 0 auto 16px auto;
    padding: 0 24px 24px 24px;
    text-align: left;
    .topBox {
      width: 1140px;
      background: #ECF8F8;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 23px 200px 18px 200px;
      align-items: center;
      position: relative;
      .topBoxItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          height: 20px;
          font-weight: 500;
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          text-align: center;
          font-style: normal;
        }
        span {
          height: 33px;
          font-weight: 500;
          font-size: 24px;
          color: #00AAA6;
          line-height: 33px;
          text-align: center;
          font-style: normal;
        }
      }
      .rightTips {
        position: absolute;
        right: 16px;
        top: 16px;
        color: #777777;
        font-size: 12px;
        span {
          color: #00AAA6;
        }
      }
    }
  }
  .policyWindow-bottom {
    width: 1188px;
    background: #FFFFFF;
    height: 100%;
    padding: 24px  24px 4px 24px;
    margin: 0 auto 40px auto ;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &-item {
      width: 369px;
      height: 172px;
      background: #F7F7F7;
      border-radius: 6px;
      margin-right: 16px;
      margin-bottom: 16px;
      position: relative;
      .textBox{
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: left;
        padding:14px 0 0 16px;
        p{
          //height: 14px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          //line-height: 14px;
        }
        .other {
          margin-top: 12px;
          font-weight: 400;
          color: #777777!important;
          height: 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
        .firstText {
          display:flex;
          align-items: center;
          justify-content: flex-start;
          width: 267px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          white-space: initial;
          word-break: break-all;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 500;
          font-size: 16px;
          color: #262626;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
        .lastText {
          font-weight: 400;
          line-height: 14px;
          margin-top: 4px;

        }
        .tips{
          height: 16px;
          font-size: 12px;
          font-weight: 400;
          color: #D9161C;
          line-height: 16px;
          text-align: right;
          margin-top: 6px;
        }
        .orgTips{
          //width: 86px;
          font-size: 12px;
          font-weight: 400;
          color: #FF8F3C;
        }
        .hj {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          margin-top:18px;
          .fl {
            font-size: 14px;
            color: #262626;
          }
          .jg {
            color: #262626;

          }
          .before {
            font-size: 22px;
            font-weight: 500;
            color: #FF8F3C;
          }
          .after {
            font-size: 14px;
            font-weight: 500;
            color: #FF8F3C;
          }
          .dx {
            color: #262626;
          }
        }
      }
      .view{
        position: absolute;
        bottom: 16px;
        right:16px;
        width: 72px;
        height: 32px;
        border-radius: 5px;
        border: 1px solid #CCCCCC;
        color:#777;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .viewGreen{
        position: absolute;
        bottom: 16px;
        right:16px;
        width: 72px;
        height: 32px;
        border-radius: 5px;
        border: 1px solid #00AAA6;
        color:#00AAA6;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .tipBox {
        position: absolute;
        right: 16px;
        top: 16px;
        height: 20px;
        font-weight: 400;
        font-size: 14px;
        color: #00AAA6;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
    }
    &-item:nth-child(3n+0){
      margin-right: 0px;
    }
    .noList {
      width: 1188px;
      height: 260px;
      background: #fff;
      margin: 16px auto;
      padding-bottom: 24px;
      color: #777;
      text-align: center;

      img {
        display: inline-block;
        width: 160px;
        height: 120px;
        margin: 48px auto 16px;
      }

      p {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
      }
    }
  }
}
</style>