<template>
  <div class="activityOutBoxtobe">
    <div class="activityBox">
      <div class="topBox">
        <div class="topBoxItem">
          <p>待确认(元)</p>
          <span>{{dataInfo.pendingConfirmationTotalAmount?dataInfo.pendingConfirmationTotalAmount.toFixed(2):'0.00'}}</span>
          <p class="total">合计<span>{{dataInfo.pendingConfirmationCount?dataInfo.pendingConfirmationCount:'0'}}</span>笔</p>
        </div>
        <div class="topBoxItem">
          <p>申诉中(元)</p>
          <span>{{dataInfo.underAppealTotalAmount?dataInfo.underAppealTotalAmount.toFixed(2):'0.00'}}</span>
          <p class="total">合计<span>{{dataInfo.underAppealCount?dataInfo.underAppealCount:0}}</span>笔</p>
        </div>
      </div>
    </div>
    <div class="policyWindow-bottom" v-if="policyWindowList.length>0">
      <div class="policyWindow-bottom-item" v-for="(item,index) in policyWindowList" :key="index">
        <a-checkbox class="checkbox" v-model="item.checked" @click="changeBox(item)"/>
        <div class="right">
          <div class="textBox">
            <p class="firstText" :title="item.activityName"> {{item.activityName}}</p>
            <p class="other" >活动期间:<span style="margin-left:10px" v-if="item.startTime&&item.endTime">{{item.startTime}} ~ {{item.endTime}}</span><span style="margin-left:10px" v-if="!item.startTime||!item.endTime">{{item.startTime}}{{item.endTime}}</span>
            </p>
            <div class="lastText hj">
              <p>
                <span class="fl" style="margin-right:10px">返利:</span><span class="jg before">{{ item.rewardAmount ? Number(item.rewardAmount).toFixed(2).toString().split('.')[0] : '0' }}</span><span class="jg after"><i class="dian">.</i>{{ item.rewardAmount ? Number(item.rewardAmount).toFixed(2).toString().split('.')[1] : '00'}}</span>
              </p>
            </div>
          </div>
          <div class="view" @click="gotoDetail(item)">查看详情</div>
          <div class="tipBox">{{item.policyType}}</div>
        </div>
      </div>
    </div>
    <div class="policyWindow-bottom" v-else>
      <div class="noList" v-if="policyWindowList.length == 0">
        <img :src="nolistImg" alt="">
        <p style="color:#777;">
          本政策无法提供明细，如需核对，请联系海信业务人员。
        </p>
      </div>
    </div>
    <span class="anchor" ref="footerRef"></span>
    <div class="pagefoot-wrap" v-if="!isFixed" :class="{ 'fixed-div': isFixed }">
      <div class="bottomButton">
        <div class="allcheck">
          <div class="allIn">
            <a-checkbox
              class="check"
              @click="allCherk"
              v-model="allCherkFlag"
            />
            <p>
              全选
            </p>
          </div>
          <div class="bigabox">
            <div class="lastText hj">
              <p>
                <span class="fl">合计:</span>
                <span class="jg before">{{ totalAmount ? Number(totalAmount).toFixed(2).toString().split('.')[0] : '0' }}</span>
                <span class="jg after"><i class="dian">.</i>{{ totalAmount ? Number(totalAmount).toFixed(2).toString().split('.')[1] : '00'}}</span>
              </p>
            </div>
            <h-btn
              class="btn"
              :width="84"
              :height="36"
              content="批量确认"
              type="primary"
              fontSize="16"
              borderRadius="4"
              @click="batchOk('confirm')"
            />
            <h-btn
              class="btn"
              :width="84"
              :height="36"
              content="批量申诉"
              type="primary"
              fontSize="16"
              borderRadius="4"
              style="margin-right: 24px"
              @click="batchOk('applear')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="pagefoot-wrap" v-if="isFixed" :class="{ 'fixed-div': isFixed }">
      <div class="bottomButton">
        <div class="allcheck">
          <div class="allIn">
            <a-checkbox
              class="check"
              @click="allCherk"
              v-model="allCherkFlag"
            />
            <p>
              全选
            </p>
          </div>
          <div class="bigabox">
            <div class="lastText hj">
              <p>
                <span class="fl">合计:</span>
                <span class="jg before">{{ totalAmount ? Number(totalAmount).toFixed(2).toString().split('.')[0] : '0' }}</span>
                <span class="jg after"><i class="dian">.</i>{{ totalAmount ? Number(totalAmount).toFixed(2).toString().split('.')[1] : '00'}}</span>
              </p>
            </div>
            <h-btn
              class="btn"
              :width="84"
              :height="36"
              content="批量确认"
              type="primary"
              fontSize="16"
              borderRadius="4"
              @click="batchOk('confirm')"
            />
            <h-btn
              @click="batchOk('applear')"
              class="btn"
              :width="84"
              :height="36"
              content="批量申诉"
              type="primary"
              fontSize="16"
              borderRadius="4"
              style="margin-right:24px"
            />
          </div>
        </div>

      </div>
    </div>
    <confirm
      :visible.sync="visible"
      info="是否确认已选中的政策无差异？"
      @deleteHandOk="conformOk($event,'1')"
    />
    <appeal
      :visible.sync="applearVisible"
      @deleteHandOk="conformOk($event,'2')"
    />
  </div>
</template>
<script>
import confirm from './confirm'
import appeal from './appeal'
import { ctsApi } from "@/views/channel/api";
export default {
  name: "",
  components:{
    confirm,
    appeal
  },
  data() {
    return {
      nolistImg: require("@/assets/IntentionList/none.png"),
      applearVisible:false,
      visible:false,
      isFixed:false,
      allCherkFlag:false,
      totalAmount:0,
    };
  },
  props: {
    dataInfo: {
      type: [Array, Object],
      default: () => []
    },
    policyWindowList: {
      type: Array,
      default: () => []
    },
    monthDate: {
      type: String,
      default: ''
    },
  },
  watch:{
    policyWindowList:{
      handler:function(newval,oldval){
        if (this.policyWindowList.length> 0 ){
          this.policyWindowList.forEach(item=>{
            item.checked = false
          })
        }
      },
      deep:true,
    },
    dataInfo:{
      handler:function(newval,oldval){},
      deep:true,
    }
  },
  mounted() {
    window.addEventListener(
      "scroll",
      this.throttle(this.handleScroll, 10),
      true
    );
  },


  methods: {
    gotoDetail(item){
      // this.$router.push({
      //   path:'/policyRebate/rebateDetail',
      //   query:{
      //     type:1,
      //     monthDate:this.monthDate,
      //     item:JSON.stringify(item)
      //   }
      // })
      let routeUrl = this.$router.resolve({
          path:'/policyRebate/rebateDetail',
          query:{
            type:1,
            monthDate:this.monthDate,
            item:JSON.stringify(item)
          }
      });
      window.open(routeUrl.href, "_blank");

    },
    allCherk(e) {
      this.totalAmount = 0
      this.allCherkFlag = e.target.checked
      if (this.policyWindowList.length>0) {
        this.policyWindowList.forEach(item=>{
          item.checked = this.allCherkFlag ? true : false
          if (item.checked){
            this.totalAmount += item.rewardAmount
          }
        })
      }
    },
    changeBox(item){
      item.checked = !item.checked
      let langNum = 0
      this.totalAmount = 0
      this.$forceUpdate()
      if (this.policyWindowList.length > 0) {
        this.policyWindowList.forEach(el => {
          if (el.checked){
            langNum += 1
            this.totalAmount += el.rewardAmount
          }
        })
      }
      if (langNum == this.policyWindowList.length ){
        this.allCherkFlag = true
      }else {
        this.allCherkFlag = false
      }
    },
    batchOk(type) {
      if (type == 'confirm'){
        this.visible = true
      } else {
        this.applearVisible = true
      }
    },
    conformOk(e,type){
      let idsList = []
      if (this.policyWindowList.length>0){
         let checkItem = this.policyWindowList.filter(it => it.checked);
         idsList = checkItem.map(el => {
           return {
             clearingsheetNo:el.settlementNumber,
             confirmPeriod: this.$util.formatDate(new Date().getTime() - 86400000, 'YM'),
             feedbackStatus:type,
             appealReason:type==1?'':e,
           }
         })
      }
      if (idsList.length == 0){
        this.$message.warning('请勾选数据')
        return
      }
      let data = {
        // monthDate: this.$util.formatDate(new Date().getTime() - 86400000, 'Y-M'),
        albatrossStatusConfirmationResponseDto:idsList,
        'serviceCode':'confirmSettlementFeedbackAlbatrossInterface',
      }
      ctsApi(data).then(res=>{
        this.allCherkFlag = false
        this.$emit('getList')
      })
    },
    // 底部固定部分代码
    //throttle：
    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    },
  }
};

</script>
<style lang="less" scoped>
.activityOutBoxtobe {
  width: 100%;
  .activityBox {
    width: 1188px;
    background: #fff;
    margin: 0 auto 16px auto;
    padding: 0 24px 24px 24px;
    text-align: left;
    .topBox {
      width: 1140px;
      background: #ECF8F8;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 21px  355px 20px 355px;
      align-items: center;
      .topBoxItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          //height: 20px;
          font-weight: 500;
          font-size: 14px;
          color: #262626;
          //line-height: 20px;
          text-align: center;
          font-style: normal;
        }
        span {
          //height: 33px;
          font-weight: 500;
          font-size: 24px;
          color: #00AAA6;
          //line-height: 33px;
          text-align: center;
          font-style: normal;
          margin:4px 0 2px 0;
        }
        .total {
          //height: 17px;
          font-weight: 500;
          font-size: 12px;
          color: #777777;
          //line-height: 17px;
          text-align: center;
          font-style: normal;
          span{
            font-size: 12px;
            color: #262626;
          }
        }
      }
    }
  }
  .policyWindow-bottom {
    width: 1188px;
    background: #FFFFFF;
    height: 100%;
    padding: 24px  24px 4px 24px;
    margin: 0 auto 0px auto ;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &-item {
      width: 369px;
      height: 172px;
      background: #F7F7F7;
      border-radius: 6px;
      margin-right: 16px;
      margin-bottom: 16px;
      position: relative;
      display: flex;
      flex-direction: row;
      .checkbox {
        padding: 16px 8px 0 16px;
      }
      .right{
        .textBox{
          display: flex;
          flex-direction: column;
          width: 100%;
          text-align: left;
          padding:14px 0 0 0px;
          p{
            //height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            //line-height: 14px;
          }
          .other {
            margin-top: 12px;
            font-weight: 400;
            color: #777777!important;
            height: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            font-style: normal;
          }
          .firstText {
            display:flex;
            align-items: center;
            justify-content: flex-start;
            width: 230px;
            margin-right: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            white-space: initial;
            word-break: break-all;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-weight: 500;
            font-size: 16px;
            color: #262626;
            line-height: 22px;
            text-align: left;
            font-style: normal;
          }
          .lastText {
            font-weight: 400;
            line-height: 14px;
            margin-top: 4px;

          }
          .tips{
            height: 16px;
            font-size: 12px;
            font-weight: 400;
            color: #D9161C;
            line-height: 16px;
            text-align: right;
            margin-top: 6px;
          }
          .orgTips{
            //width: 86px;
            font-size: 12px;
            font-weight: 400;
            color: #FF8F3C;
          }
          .hj {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            margin-top:18px;
            .fl {
              font-size: 14px;
              color: #262626;
            }
            .jg {
              color: #262626;

            }
            .before {
              font-size: 22px;
              font-weight: 500;
              color: #FF8F3C;
            }
            .after {
              font-size: 14px;
              font-weight: 500;
              color: #FF8F3C;
            }
            .dx {
              color: #262626;
            }
          }
        }
        .view{
          position: absolute;
          bottom: 16px;
          right:16px;
          width: 72px;
          height: 32px;
          border-radius: 5px;
          border: 1px solid #CCCCCC;
          color:#777;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .viewGreen{
          position: absolute;
          bottom: 16px;
          right:16px;
          width: 72px;
          height: 32px;
          border-radius: 5px;
          border: 1px solid #00AAA6;
          color:#00AAA6;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .tipBox {
          position: absolute;
          right: 16px;
          top: 16px;
          height: 20px;
          font-weight: 400;
          font-size: 14px;
          color: #00AAA6;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
      }

    }
    &-item:nth-child(3n+0){
      margin-right: 0px;
    }
    .noList {
      width: 1188px;
      height: 260px;
      background: #fff;
      margin: 16px auto;
      padding-bottom: 24px;
      color: #777;
      text-align: center;

      img {
        display: inline-block;
        width: 160px;
        height: 120px;
        margin: 48px auto 16px;
      }

      p {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 20px;
      }
    }
  }
  .pagefoot-wrap {
    // background: #FFFFFF;
    height: 54px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 40px;
    z-index: 10000;

    .bottomButton {
      position: relative;
      height: 54px;
      background-color: #ffffff;
      width: 1188px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .allcheck {
        padding-left: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width:100%;
        .allIn {
          margin-right: 16px;
          p {
            margin-left: 8px;
          }
        }
        .piliang {
          margin-right: 16px;
          p {
            margin-left: 8px;
          }
        }
        .bigabox {
          .hj {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            margin-right:16px;
            .fl {
              font-size: 14px;
              color: #262626;
            }
            .jg {
              color: #262626;

            }
            .before {
              font-size: 22px;
              font-weight: 500;
              color: #FF8F3C;
            }
            .after {
              font-size: 14px;
              font-weight: 500;
              color: #FF8F3C;
            }
            .dx {
              color: #262626;
            }
          }
          .cancel {
            width: 32px;
            height: 22px;
            font-weight: 400;
            font-size: 16px;
            color: #AAAAAA;
            line-height: 22px;
            text-align: left;
            margin-right:24px;
            cursor: pointer;
          }
          .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
          }
          .btn:last-child{
            margin-left: 16px;
          }
        }


      }
      .fenye {
        margin-right:14px;
        .clearfix {
          /deep/.ant-pagination-item {
            border: 1px solid #eee;
          }
        }
      }

      div {
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .fixed-div {
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.17);
    position: fixed;
    bottom: 0;
    margin: 0 auto;
    background-color: #fff;
    height: 54px;
    left: 0;
  }
}
</style>