import http from "@/utils/request";
// 导出
export function getExport(data, url) {
  return http({
    url,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    responseType: 'blob',
    method: 'post',
    data
  })
}